
import './assets/styles/theme.css';

import { ThemeProvider, createTheme } from '@mui/material/styles'

import {BrowserRouter, Route, Routes as Switch} from 'react-router-dom';

import Signin from 'pages/auth/Signin';
import Logout from 'pages/auth/Logout';

import Dashboard from 'pages/app/Dashboard';

import ConfigProductGroups from 'pages/app/catalog/config/ConfigProductGroups';

import ConfigProductAttributes from 'pages/app/catalog/config/attributes/ConfigProductAttributes';
import ConfigProductAttributesAdd from 'pages/app/catalog/config/attributes/ConfigProductAttributesAdd';

import CatalogCategories from 'pages/app/catalog/categories/CatalogCategories';

import ConfigCategoriesAttributes from 'pages/app/catalog/categories/config/ConfigCategoriesAttributes';

import ProductsList from 'pages/app/catalog/products/ProductsList';
import ProductsForm from 'pages/app/catalog/products/ProductsForm';

import StoresList from 'pages/app/stores/StoresList';

import ProductsReviewsList from 'pages/app/catalog/reviews/ProductsReviewsList';

import CustomersList from 'pages/app/customers/CustomersList';
import CustomersAdd from 'pages/app/customers/CustomersAdd';
import CustomersGroupsList from 'pages/app/customers/CustomersGroupsList';
import CustomersGroupsAdd from 'pages/app/customers/CustomersGroupsAdd';

import CustomerTestimonialsList from 'pages/app/customers/testimonials/CustomerTestimonialsList';

import CmsPagesList from 'pages/app/cms/pages/CmsPagesList';
import CmsSections from 'pages/app/cms/sections/CmsSections';

import BlogsCategories from 'pages/app/cms/blogs/BlogsCategories';
import BlogsArticlesList from 'pages/app/cms/blogs/BlogsArticlesList';

import MenuList from 'pages/app/cms/menu/MenuList';

import CmsBannersGroups from 'pages/app/cms/banners/CmsBannersGroups';
import CmsBanners from 'pages/app/cms/banners/CmsBanners';

import EmailTemplates from 'pages/app/cms/email/EmailTemplates';
import EmailTemplatesAdd from 'pages/app/cms/email/EmailTemplatesAdd';

import SalesOrdersList from 'pages/app/sales/orders/SalesOrdersList';
import SalesOrdersView from 'pages/app/sales/orders/SalesOrdersView';

import SalesInvoiceView from 'pages/app/sales/invoices/SalesInvoiceView';

import SalesShipmentView from 'pages/app/sales/shipments/SalesShipmentView';

import SalesCouponsList from 'pages/app/sales/coupons/SalesCouponsList';

import SalesTaxList from 'pages/app/sales/tax/SalesTaxList';
import SalesTaxSlabs from 'pages/app/sales/tax/SalesTaxSlabs';

import SettingsPageMain from 'pages/app/settings/SettingsPageMain';

import WdFormRouter from 'pages/app/WdFormRouter';

function App(){

    const theme = createTheme({
        body: {
            fontSize: 12, fontFamily: 'Open Sans'
        },
        typography: {
            fontFamily: [
                'Open Sans'
            ],
        },
        shadows: [
            "none",
            "0px 15px 60px rgba(0, 0, 0, 0.25)",
            "5px 25px 60px rgba(0, 0, 0, 0.25)",
            "10px 35px 60px rgba(0, 0, 0, 0.25)",
            "15px 45px 60px rgba(0, 0, 0, 0.25)",
            ...Array(20).fill('none')
        ],
        components: {
            MuiButton: {
                styleOverrides: {
                    root: ({ ownerState }) => ({
                      
                        fontSize: 13,
                        paddingTop: 5,
                        paddingBottom: 5,
                        paddingLeft: 24,
                        paddingRight: 24,
                        transition: '.2s all ease-in-out',
                        borderRadius: 30,
                        fontWeight: 600,
                        textTransform: 'capitalize',
                        color: 'rgb(106, 130, 251)',
                        "&:disabled": {
                            color: "rgba(255,255,255,.6)"
                        },
                        background: "rgba(25, 118, 210, 0.06)",
                        "&:hover": {
                            background: "rgba(25, 118, 210, 0.11)",
                            boxShadow: 'none'
                        },

                        ...(ownerState.size === 'small' && {

                            fontSize: 12,
                            paddingTop: 3,
                            paddingBottom: 3,
                            paddingLeft: 8,
                            paddingRight: 8
                        }),
                        ...(ownerState.size === 'large' && {

                            fontSize: 16,
                            paddingTop: 12,
                            paddingBlock: 12
                        }),

                        ...(ownerState.variant === 'contained' && {

                            ...(ownerState.color === 'primary' && {
                                
                                boxShadow: 'none',
                                borderRadius: '50px',
                                fontWeight: "bold",
                                background: '#C4DFDF',
                                "&:hover": {
                                    background: "linear-gradient(to right, #6a82fb, #fc5c7d)",
                                    boxShadow: 'none'
                                },
                                color: '#ffffff',
                            }),
                            ...(ownerState.color === 'secondary' && {

                                background: '#00652E',
                                color: '#ffffff',
                                boxShadow: 'none',
                                "&:hover": {
                                    background: "#333",
                                    boxShadow: 'none'
                                },
                            }),
                            ...(ownerState.size === 'small' && {

                                fontSize: 13,
                                paddingTop: 3,
                                paddingBottom: 3,
                                paddingLeft: 18,
                                paddingRight: 18
                            }),
                        }),

                        ...(ownerState.variant === 'outlined' && {

                            ...(ownerState.color === 'secondary' && {

                                background: 'rgb(106, 130, 251, .9)',
                                border: '1px solid transparent',
                                color: '#fff',
                                "&:hover": {
                                    background: "rgb(106, 130, 251, 1)",
                                    border: '1px solid transparent',
                                },
                            }),

                            ...(ownerState.color === 'primary' && {

                                ...(ownerState.size === 'small' && {

                                    background: 'transparent',
                                    border: '1px solid rgb(106, 130, 251, .9)',
                                    color: 'rgb(106, 130, 251, .9)',
                                    "&:hover": {
                                        background: "rgb(106, 130, 251, 1)",
                                        border: '1px solid rgb(106, 130, 251, .9)',
                                        color: '#fff',
                                    },
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    paddingLeft: 10,
                                    paddingRight: 10,
                                    fontSize: 12,
                                    alignSelf: 'center'
                                })
                            }),
                        }),
                    })
                }
            },
            MuiCircularProgress: {
                styleOverrides: {

                    root: ({ ownerState }) => ({

                        ...(ownerState.color === 'primary' && {

                            color: '#ffffff'
                        }),
                        ...(ownerState.color === 'secondary' && {

                            color: 'rgb(42,35,108)'
                        })
                    })
                }
            },
            MuiPaper: {
                styleOverrides: {

                    root: {
                        backgroundColor: '#ffffff'
                    }
                }
            },
            MuiOutlinedInput: {
                styleOverrides: {

                    root: {
                        backgroundColor: '#fff'
                    },
                    notchedOutline: {
                        borderWidth: 1,
                        borderRadius: 0
                    }
                }
            },
            MuiInputBase: {
                styleOverrides: {

                    inputSizeSmall: {
                        height: '1.5rem',
                        fontSize: 15,
                        borderRadius: 0
                    }
                }
            },
            MuiToolbar: {
                styleOverrides: {
                    root: {
                        minHeight: '50px'
                    }
                }
            }
        }
    })

    return (
        <ThemeProvider theme={theme}>
            <BrowserRouter basename={'/'}>

                <Switch>
                
                    <Route exact={true} path='/' element={<Signin />} />

                    <Route path="/logout" element={<Logout />}>
                            
                        <Route path=':auto_logout' element={<Logout />} />
                    </Route>
                    
                    <Route exact={true} path='/dashboard' element={<Dashboard />} />

                    <Route exact={true} path='/stores' element={<StoresList />} />

                    <Route exact={true} path='/catalog/products/config/product-groups' element={<ConfigProductGroups />} />

                    <Route exact={true} path='/catalog/products/config/attributes' element={<ConfigProductAttributes />} />
                    <Route path="/catalog/products/config/attributes/add" element={<ConfigProductAttributesAdd />}>
                            
                        <Route path=':row_id' element={<ConfigProductAttributesAdd />} />
                    </Route>

                    <Route exact={true} path='/catalog/categories' element={<CatalogCategories />} />

                    <Route exact={true} path='/catalog/categories/config/attributes' element={<ConfigCategoriesAttributes />} />

                    <Route exact={true} path='/catalog/products' element={<ProductsList />} />

                    <Route exact={true} path='/catalog/products/add' element={<ProductsForm />}>
                        <Route path=':product_group' element={<ProductsForm />}>
                            <Route path=':row_id' element={<ProductsForm />}>
                                <Route path=':product_type' element={<ProductsForm />} />
                            </Route>
                        </Route>
                    </Route>

                    <Route exact={true} path='/catalog/products/reviews' element={<ProductsReviewsList />} />

                    <Route exact={true} path='/customers' element={<CustomersList />} />
                    <Route path="/customers/add" element={<CustomersAdd />}>
                        <Route path=':row_id' element={<CustomersAdd />} />
                    </Route>

                    <Route exact={true} path='/customers/groups' element={<CustomersGroupsList />} />
                    <Route path="/customers/groups/add" element={<CustomersGroupsAdd />}>
                        <Route path=':row_id' element={<CustomersGroupsAdd />} />
                    </Route>

                    <Route exact={true} path='/customers/testimonials' element={<CustomerTestimonialsList />} />

                    <Route exact={true} path='/cms/pages' element={<CmsPagesList />} />

                    <Route exact={true} path='/cms/sections' element={<CmsSections />} />

                    <Route exact={true} path='/cms/banners/group' element={<CmsBannersGroups />} />
                    <Route exact={true} path='/cms/banners' element={<CmsBanners />} />

                    <Route exact={true} path='/cms/emails' element={<EmailTemplates />} />
                    <Route path="/cms/emails/add" element={<EmailTemplatesAdd />}>
                        <Route path=':row_id' element={<EmailTemplatesAdd />} />
                    </Route>

                    <Route exact={true} path='/cms/menu' element={<MenuList />} />

                    <Route exact={true} path='/cms/blogs/categories' element={<BlogsCategories />} />
                    <Route exact={true} path='/cms/blogs/articles' element={<BlogsArticlesList />} />

                    <Route exact={true} path='/sales/coupons' element={<SalesCouponsList />} />

                    <Route exact={true} path='/sales/orders' element={<SalesOrdersList />} />
                    <Route path="/sales/orders/view" element={<SalesOrdersView />}>
                        <Route path=':row_id' element={<SalesOrdersView />} />
                    </Route>

                    <Route path="/sales/invoice/preview" element={<SalesInvoiceView />}>
                        <Route path=':order_id' element={<SalesInvoiceView />} />
                    </Route>

                    <Route path="/sales/shipment/preview" element={<SalesShipmentView />}>
                        <Route path=':order_id' element={<SalesShipmentView />} />
                    </Route>

                    <Route exact={true} path='/sales/tax' element={<SalesTaxList />} />
                    <Route exact={true} path='/sales/tax/slabs' element={<SalesTaxSlabs />} />

                    <Route path="/settings" element={<SettingsPageMain />}>
                        <Route path=':setting' element={<SettingsPageMain />} />
                    </Route>

                    <Route exact={true} path='/edit' element={<WdFormRouter />}>
                        <Route path=':main_route' element={<WdFormRouter />}>
                            <Route path=':module' element={<WdFormRouter />}>
                                <Route path=':action' element={<WdFormRouter />}>
                                    <Route path=':row_id' element={<WdFormRouter />} />
                                </Route>
                            </Route>
                        </Route>
                    </Route>

                    <Route exact={true} path='/edit' element={<WdFormRouter />}>
                        <Route path=':main_route' element={<WdFormRouter />}>
                            <Route path=':module' element={<WdFormRouter />}>
                                <Route path=':action' element={<WdFormRouter />}>
                                    <Route path=':sub_action' element={<WdFormRouter />}>
                                        <Route path=':row_id' element={<WdFormRouter />} />
                                    </Route>
                                </Route>
                            </Route>
                        </Route>
                    </Route>
                </Switch>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
