import React, { Component } from 'react';

import TextField from '@mui/material/TextField';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';

import Switch from '@mui/material/Switch';

import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';

import MenuItem from '@mui/material/MenuItem';

import Grid from '@mui/material/Grid';

import Btn from 'components/Btn';

import Edit from '@mui/icons-material/Edit';
import Delete from '@mui/icons-material/Delete';
import CloudUpload from '@mui/icons-material/CloudUpload';
import Close from '@mui/icons-material/Close';

import Loader from 'components/Loader';

import Api from 'api/Api';

class ProductVariables extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,

            options_error: false,

            selected_attributes: [],

            show_manage_attributes: false,

            attribute_products: [],

            uploading_index: false,
        }
    }

    componentDidMount = () => {

        var account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        
        if(account_token){
            
            this.setState({account_token: account_token})
        }
    }

    render(){

        return (
            <>
                <Grid container spacing={3}>
                    <Grid item xs={12}>

                        {(this.props.attribute_products.length > 0 && this.state.show_manage_attributes)
                            ?
                                <div>
                                    <div className='space-between'>

                                        {this.inputs('edit')}
                                    </div>
                                </div>
                            :
                                <>

                                    {(this.state.show_manage_attributes === false && this.props.attribute_products.length === 0) &&

                                        this.inputs('add')
                                    }
                                </>
                        }
                    </Grid>
                    <Grid item xs={12}>

                        {(this.props.attribute_products.length > 0 && this.state.show_manage_attributes === false) &&

                            <div className='vertical'>

                                <div className='space-between'>
                                    <label className='fs-13 fw-bold'>Variable Products: <span className='fw-semibold'>{this.props.attribute_products.length}</span></label>

                                    <Btn size="small" startIcon={<Edit style={{fontSize: 16}} />} onClick={() => {

                                        this.setState({show_manage_attributes: true})
                                    }}>
                                        Manage Variable Products
                                    </Btn>
                                </div>

                                <Table className='table mt-10'>
                                    <TableBody>

                                        <TableRow style={{backgroundColor:'rgba(0,0,0,.1)'}}>
                                            <TableCell>
                                                <label className='fs-12 fw-bold gr-6'>Product</label>
                                            </TableCell>
                                            <TableCell width={80}>
                                                <label className='fs-12 fw-bold gr-6'>Price</label>
                                            </TableCell>
                                            <TableCell width={80}>
                                                <label className='fs-12 fw-bold gr-6'>Sp. Price</label>
                                            </TableCell>
                                            <TableCell width={80}>
                                                <label className='fs-12 fw-bold gr-6'>Quantity</label>
                                            </TableCell>
                                            <TableCell width={80}>
                                                <label className='fs-12 fw-bold gr-6'>Stock Status</label>
                                            </TableCell>
                                            <TableCell width={80}>
                                                <label className='fs-12 fw-bold gr-6'>Image</label>
                                            </TableCell>
                                            <TableCell>
                                                <label className='fs-12 fw-bold gr-6'>Status</label>
                                            </TableCell>
                                        </TableRow>

                                        {this.props.attribute_products.map((_attribute_product, index) => {

                                            return (
                                                <React.Fragment key={`attribute_product_${index}`}>
                                                    <TableRow>
                                                        <TableCell>
                                                            <strong className='fs-11 gr-7'>{_attribute_product.code}</strong>
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                placeholder="Price"
                                                                variant="standard"
                                                                size="small"
                                                                value={_attribute_product.price}
                                                                onChange={(e) => {

                                                                    let attribute_products = this.props.attribute_products

                                                                    attribute_products[index]['price'] = e.target.value

                                                                    this.setState({attribute_products: attribute_products})

                                                                    this.props.updateAttributes(attribute_products)
                                                                }}
                                                                onBlur={(e) => {

                                                                    let attribute_products = this.props.attribute_products

                                                                    if(e.target.value !== '' && !isNaN(e.target.value)){

                                                                        if(_attribute_product.hasOwnProperty('price_error')){

                                                                            delete attribute_products[index]['price_error']
                                                                        }

                                                                        this.props.updateAttributes(attribute_products)
                                                                    }
                                                                }}
                                                                error={_attribute_product.hasOwnProperty('price_error') ? true : false}
                                                                helperText={_attribute_product.hasOwnProperty('price_error') ? <span style={{lineHeight: 1, display: 'block'}}>Invalid price.</span> : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                placeholder="Special Price"
                                                                variant="standard"
                                                                size="small"
                                                                value={_attribute_product.special_price}
                                                                onChange={(e) => {

                                                                    let attribute_products = this.props.attribute_products

                                                                    attribute_products[index]['special_price'] = e.target.value

                                                                    this.setState({attribute_products: attribute_products})

                                                                    this.props.updateAttributes(attribute_products)
                                                                }}
                                                                onBlur={(e) => {

                                                                    let attribute_products = this.props.attribute_products

                                                                    if(e.target.value !== '' && !isNaN(e.target.value)){

                                                                        if(_attribute_product.hasOwnProperty('special_price_error')){

                                                                            delete attribute_products[index]['special_price_error']
                                                                        }

                                                                        this.props.updateAttributes(attribute_products)
                                                                    }
                                                                }}
                                                                error={_attribute_product.hasOwnProperty('special_price_error') ? true : false}
                                                                helperText={_attribute_product.hasOwnProperty('special_price_error') ? <span style={{lineHeight: 1, display: 'block'}}>Invalid spl. price.</span> : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <TextField
                                                                fullWidth
                                                                size="small"
                                                                placeholder="Quantity"
                                                                variant="standard"
                                                                value={_attribute_product.qty}
                                                                onChange={(e) => {

                                                                    let attribute_products = this.props.attribute_products

                                                                    attribute_products[index]['qty'] = e.target.value

                                                                    this.setState({attribute_products: attribute_products})
                                                                    this.props.updateAttributes(attribute_products)
                                                                }}
                                                                onBlur={(e) => {

                                                                    let attribute_products = this.props.attribute_products

                                                                    if(e.target.value !== '' && !isNaN(e.target.value)){

                                                                        if(_attribute_product.hasOwnProperty('qty_error')){

                                                                            delete attribute_products[index]['qty_error']
                                                                        }

                                                                        this.props.updateAttributes(attribute_products)
                                                                    }
                                                                }}
                                                                error={_attribute_product.hasOwnProperty('qty_error') ? true : false}
                                                                helperText={_attribute_product.hasOwnProperty('qty_error') ? <span style={{lineHeight: 1, display: 'block'}}>Invalid qty.</span> : ''}
                                                            />
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl
                                                                size="small"
                                                                fullWidth
                                                                error={_attribute_product.hasOwnProperty('stock_status_error') ? true : false}
                                                            >
                                                                <Select
                                                                    fullWidth
                                                                    variant='standard'
                                                                    size='small'
                                                                    value={_attribute_product.stock_status}
                                                                    placeholder="Stock Status"
                                                                    onChange={(e) => {

                                                                        let attribute_products = this.props.attribute_products

                                                                        attribute_products[index]['stock_status'] = e.target.value

                                                                        if(_attribute_product.hasOwnProperty('stock_status_error')){

                                                                            delete attribute_products[index]['stock_status_error']
                                                                        }

                                                                        this.setState({attribute_products: attribute_products})
                                                                        this.props.updateAttributes(attribute_products)
                                                                    }}
                                                                >
                                                                    <MenuItem value={1}><span className='fs-11'>In Stock</span></MenuItem>
                                                                    <MenuItem value={0}><span className='fs-11'>Out Of Stock</span></MenuItem>
                                                                </Select>

                                                                {
                                                                    (_attribute_product.hasOwnProperty('stock_status_error')) &&
                                                                        
                                                                        <FormHelperText sx={{margin: 0, lineHeight: 1}}>Required entry.</FormHelperText>
                                                                }
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <FormControl fullWidth className='wd-form-select'>
                                                                <div className='align-start vertical'>
                                                                    <div style={{position: 'relative'}}>
                    
                                                                        <div className='align-start'>

                                                                            {
                                                                                (_attribute_product.hasOwnProperty('image_url') && _attribute_product.image_url !== '')
                                                                                    ?

                                                                                        <div className='vertical'>
                                                                                            <>
                                                                                                <div className='uploaded-item'>

                                                                                                    <Btn icon={true} size="small" style={{position: 'absolute', right: 4, top: 5, backgroundColor: 'rgba(255,255,255,.8)', padding: 3, zIndex: 99}} confirm={true} confirm_message="Do you really want to remove this image?" onClick={() => {

                                                                                                        let attribute_products = this.props.attribute_products

                                                                                                        attribute_products[index]['image_url'] = ''
                                                                                                        attribute_products[index]['image'] = ''

                                                                                                        this.setState({attribute_products: attribute_products})
                                                                                                        this.props.updateAttributes(attribute_products)
                                                                                                    }}>
                                                                                                        <Close className='c-red' style={{fontSize: 16}} />
                                                                                                    </Btn>

                                                                                                    <a target='_blank' href={_attribute_product.image_url}>
                                                                                                        <div className='align-center vertical'>
                                                                                                            
                                                                                                            <div style={{minHeight: 80, minWidth: 80}} className='align-center'>

                                                                                                                <img src={_attribute_product.image_url} style={{maxWidth: 80, maxHeight: 80, zIndex: 9}} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </a>
                                                                                                </div>

                                                                                                <div>
                                                                                                    <label style={{backgroundColor: 'rgba(106, 130, 251, .2)', cursor: 'pointer', padding: '3px 20px', color: '#000', fontWeight: 'bold', display: 'flex', borderRadius: '20px', alignItems: 'center', justifyContent:'center', marginTop: 5}}>
                                                                                                        <input type="file" className="hidden" onChange={(e) => {

                                                                                                            this.uploadImage(e, _attribute_product, index)
                                                                                                        }} accept='jpg,jpeg,png,webp' />

                                                                                                        <span className='fs-11'>Change</span>
                                                                                                    </label>
                                                                                                </div>
                                                                                            </>
                                                                                        </div>
                                                                                    :

                                                                                        <label className="upload-panel sm" style={{width: 110, height: 40}}>
                                                                                            <input type="file" className="hidden" onChange={(e) => {

                                                                                                this.uploadImage(e, _attribute_product, index)
                                                                                            }} accept='jpg,jpeg,png,webp' />

                                                                                            <div className='upload-panel-button' style={{height: 5}}>
                                                                                                <CloudUpload />
                                                                                                <span className='fs-11'>Upload</span>
                                                                                            </div>
                                                                                        </label>
                                                                            }
                                                                        </div>

                                                                        <Loader loading={this.state.uploading_index === index ? true : false} />
                                                                    </div>
                                                                </div>
                                                            </FormControl>
                                                        </TableCell>
                                                        <TableCell>
                                                            <Switch
                                                                checked={_attribute_product.status == '1' ? true : false}
                                                                onChange={(e) => {

                                                                    let attribute_products = this.props.attribute_products

                                                                    attribute_products[index]['status'] = e.target.checked === true ? '1' : '0'

                                                                    this.setState({attribute_products: attribute_products})
                                                                    this.props.updateAttributes(attribute_products)
                                                                }}
                                                                size="small"
                                                            />
                                                        </TableCell>
                                                    </TableRow>

                                                    {this.props.stores.length > 0 &&
                                                    
                                                        <TableRow>
                                                            <TableCell colSpan={7}>
                                                                <Table size="small" className='table'>
                                                                    <TableHead>
                                                                        <TableRow>
                                                                            <TableCell width="30%">
                                                                                <strong className='fs-10'>Store</strong>
                                                                            </TableCell>
                                                                            {/* <TableCell width="15%">
                                                                                <strong className='fs-10'>Price</strong>
                                                                            </TableCell>
                                                                            <TableCell width="15%">
                                                                                <strong className='fs-10'>Sp. Price</strong>
                                                                            </TableCell> */}
                                                                            <TableCell width="15%">
                                                                                <strong className='fs-10'>Quantity</strong>
                                                                            </TableCell>
                                                                            <TableCell width="15%">
                                                                                <strong className='fs-10'>Stock Status</strong>
                                                                            </TableCell>
                                                                            <TableCell width="10%">
                                                                                <strong className='fs-10'>Status</strong>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </TableHead>
                                                                    <TableBody>

                                                                        {this.props.stores.map((_store, _store_index) => {

                                                                            return (
                                                                                <TableRow key={`store_${_store.row_id}_${index}`}>
                                                                                    <TableCell>
                                                                                        <strong className='fs-12'>{_store.title}</strong>
                                                                                    </TableCell>
                                                                                    {/* <TableCell>
                                                                                        <TextField
                                                                                            label="Price"
                                                                                            variant="standard"
                                                                                            size='small'
                                                                                            placeholder='Enter Price'
                                                                                            value={this.optionStoreValue(_store, _attribute_product, 'price')}
                                                                                            onChange={(e) => {

                                                                                                let attribute_products = this.props.attribute_products

                                                                                                if(!attribute_products[index].hasOwnProperty('store')){

                                                                                                    attribute_products[index]['store'] = [];
                                                                                                    attribute_products[index]['store'][_store_index] = {};
                                                                                                }

                                                                                                attribute_products[index]['store'][_store_index]['store_id'] = _store.row_id;

                                                                                                attribute_products[index]['store'][_store_index]['price'] = e.target.value;

                                                                                                this.setState({attribute_products: attribute_products})

                                                                                                this.props.updateAttributes(attribute_products)
                                                                                            }}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <TextField
                                                                                            label="Special Price"
                                                                                            variant="standard"
                                                                                            size='small'
                                                                                            placeholder='Enter Special Price'
                                                                                            value={this.optionStoreValue(_store, _attribute_product, 'special_price')}
                                                                                            onChange={(e) => {

                                                                                                let attribute_products = this.props.attribute_products

                                                                                                if(!attribute_products[index].hasOwnProperty('store')){

                                                                                                    attribute_products[index]['store'] = [];
                                                                                                    attribute_products[index]['store'][_store_index] = {};
                                                                                                }

                                                                                                attribute_products[index]['store'][_store_index]['store_id'] = _store.row_id;

                                                                                                attribute_products[index]['store'][_store_index]['special_price'] = e.target.value;

                                                                                                this.setState({attribute_products: attribute_products})

                                                                                                this.props.updateAttributes(attribute_products)
                                                                                            }}
                                                                                        />
                                                                                    </TableCell> */}
                                                                                    <TableCell>
                                                                                        <TextField
                                                                                            label="Quantity"
                                                                                            variant="standard"
                                                                                            size='small'
                                                                                            placeholder='Quantity'
                                                                                            value={this.optionStoreValue(_store, _attribute_product, 'qty')}
                                                                                            onChange={(e) => {

                                                                                                let attribute_products = this.props.attribute_products

                                                                                                if(!attribute_products[index].hasOwnProperty('store')){

                                                                                                    attribute_products[index]['store'] = [];
                                                                                                    attribute_products[index]['store'][_store_index] = {};
                                                                                                }else{

                                                                                                    if(!attribute_products[index]['store'].hasOwnProperty(_store_index)){

                                                                                                        attribute_products[index]['store'][_store_index] = {};
                                                                                                    }
                                                                                                }

                                                                                                attribute_products[index]['store'][_store_index]['store_id'] = _store.row_id;

                                                                                                attribute_products[index]['store'][_store_index]['qty'] = e.target.value;

                                                                                                this.setState({attribute_products: attribute_products})

                                                                                                this.props.updateAttributes(attribute_products)
                                                                                            }}
                                                                                            onBlur={(e) => {

                                                                                                let attribute_products = this.props.attribute_products

                                                                                                if(
                                                                                                    attribute_products[index].hasOwnProperty('store')
                                                                                                    &&
                                                                                                    attribute_products[index]['store'].hasOwnProperty(_store_index)
                                                                                                    &&
                                                                                                    attribute_products[index]['store'][_store_index].hasOwnProperty('qty_error')
                                                                                                ){

                                                                                                    delete attribute_products[index]['store'][_store_index]['qty_error']
                                                                                                }

                                                                                                this.setState({attribute_products: attribute_products})

                                                                                                this.props.updateAttributes(attribute_products)
                                                                                            }}
                                                                                            error={this.storeFieldError(_store, _attribute_product, 'qty_error')}
                                                                                            helperText={this.storeFieldError(_store, _attribute_product, 'qty_error') ? <span style={{lineHeight: 1, display: 'block'}}>Invalid qty</span> : ''}
                                                                                        />
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <FormControl
                                                                                            variant="standard"
                                                                                            size='small'
                                                                                            fullWidth
                                                                                            error={this.storeFieldError(_store, _attribute_product, 'stock_status_error')}
                                                                                        >
                                                                                            <InputLabel>Stock Status</InputLabel>
                                                                                            <Select
                                                                                                size='small'
                                                                                                fullWidth
                                                                                                label="Stock Status"
                                                                                                value={this.optionStoreValue(_store, _attribute_product, 'stock_status')}
                                                                                                onChange={(e) => {

                                                                                                    let attribute_products = this.props.attribute_products

                                                                                                    if(!attribute_products[index].hasOwnProperty('store')){

                                                                                                        attribute_products[index]['store'] = [];
                                                                                                        attribute_products[index]['store'][_store_index] = {};
                                                                                                    }else{

                                                                                                        if(!attribute_products[index]['store'].hasOwnProperty(_store_index)){

                                                                                                            attribute_products[index]['store'][_store_index] = {};
                                                                                                        }
                                                                                                    }

                                                                                                    attribute_products[index]['store'][_store_index]['store_id'] = _store.row_id;

                                                                                                    attribute_products[index]['store'][_store_index]['stock_status'] = e.target.value;

                                                                                                    if(
                                                                                                        attribute_products[index].hasOwnProperty('store')
                                                                                                        &&
                                                                                                        attribute_products[index]['store'].hasOwnProperty(_store_index)
                                                                                                        &&
                                                                                                        attribute_products[index]['store'][_store_index].hasOwnProperty('stock_status_error')
                                                                                                    ){
    
                                                                                                        delete attribute_products[index]['store'][_store_index]['stock_status_error']
                                                                                                    }

                                                                                                    this.setState({attribute_products: attribute_products})

                                                                                                    this.props.updateAttributes(attribute_products)
                                                                                                }}
                                                                                            >
                                                                                                <MenuItem value="1">
                                                                                                    <span className='fs-11'>In Stock</span>
                                                                                                </MenuItem>
                                                                                                <MenuItem value="0">
                                                                                                    <span className='fs-11'>Out Of Stock</span>
                                                                                                </MenuItem>
                                                                                            </Select>

                                                                                            {this.storeFieldError(_store, _attribute_product, 'stock_status_error') &&

                                                                                                <FormHelperText sx={{lineHeight: 1}}>Required entry.</FormHelperText>
                                                                                            }
                                                                                        </FormControl>
                                                                                    </TableCell>
                                                                                    <TableCell>
                                                                                        <Switch
                                                                                            checked={this.optionStoreValue(_store, _attribute_product, 'status') == '1' ? true : false}
                                                                                            onChange={(e) => {

                                                                                                let attribute_products = this.props.attribute_products

                                                                                                if(!attribute_products[index].hasOwnProperty('store')){

                                                                                                    attribute_products[index]['store'] = [];
                                                                                                    attribute_products[index]['store'][_store_index] = {};
                                                                                                }else{

                                                                                                    if(!attribute_products[index]['store'].hasOwnProperty(_store_index)){

                                                                                                        attribute_products[index]['store'][_store_index] = {};
                                                                                                    }
                                                                                                }

                                                                                                attribute_products[index]['store'][_store_index]['store_id'] = _store.row_id;

                                                                                                attribute_products[index]['store'][_store_index]['status'] = e.target.checked === true ? '1' : '0';

                                                                                                this.setState({attribute_products: attribute_products})

                                                                                                this.props.updateAttributes(attribute_products)
                                                                                            }}
                                                                                            size="small"
                                                                                        />
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            )
                                                                        })}
                                                                    </TableBody>
                                                                </Table>
                                                            </TableCell>
                                                        </TableRow>
                                                    }

                                                </React.Fragment>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </div>
                        }
                    </Grid>
                </Grid>
            </>
        )
    }

    recurse = (arr, pre) => {

        if(!arr.length){

            return pre;
        }
      
        let that = this;

        let ans = arr[0].reduce(function(ans, value){
          
            return ans.concat(that.recurse(arr.slice(1), `${pre !== '' ? `${pre}-` : ''}${value.value}_${value.id}`));
        }, []);
      
        return ans;
    }

    inputs = (action) => {

        return (
            <Grid container spacing={3}>
                <Grid item xs={3}>
                    <FormControl size="small" fullWidth>
                        <InputLabel>Variable Attributes</InputLabel>
                        <Select
                            fullWidth
                            variant='standard'
                            label="Variable Attributes"
                            value=""
                            onChange={(e) => {

                                let selected_attributes = this.state.selected_attributes;
                                let product_group_attributes = this.props.product_group_attributes;

                                let _row = product_group_attributes.find(row => row.key === e.target.value);

                                if(_row){

                                    selected_attributes.push(_row);
                                }

                                this.setState({selected_attributes: selected_attributes})
                            }}
                        >
                            {this.props.product_group_attributes.map((product_group_attribute, index) => {

                                if(
                                    product_group_attribute.type === 'dropdown'
                                    &&
                                    this.state.selected_attributes.findIndex(row => row.key === product_group_attribute.key) <= -1
                                ){
                            
                                    return (
                                        <MenuItem
                                            key={`product_group_attribute_${index}`}
                                            value={product_group_attribute.key}
                                        >
                                            {product_group_attribute.value}
                                        </MenuItem>
                                    )
                                }
                            })}
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={9}>
                
                    {this.state.selected_attributes.length > 0 &&

                        <>

                            {this.state.selected_attributes.map((_selected_attribute, index) => {

                                return (
                                    <div key={`selected_attribute_${_selected_attribute.key}`} className='vertical mb-5 has-hoverable-action'>

                                        <div className='space-between'>
                                            <label className='fs-13 fw-bold c-blue'>{_selected_attribute.value}</label>

                                            {this.props.attribute_products.length === 0 &&

                                                <Btn icon={true} size="small" className="hoverable-action" confirm={true} confirm_message="Do you really want to remove this attribute?" onClick={() => {

                                                    let selected_attributes = this.state.selected_attributes;

                                                    selected_attributes.splice(index, 1);

                                                    this.setState({selected_attributes: selected_attributes})
                                                }}>
                                                    <Delete className='c-red' style={{fontSize: 16}} />
                                                </Btn>
                                            }
                                        </div>

                                        <FormGroup size="small" row>

                                            {_selected_attribute.options.map((_option, _index) => {

                                                return (
                                                    <FormControlLabel
                                                        key={`selected_attribute_${_selected_attribute.key}_option_${_option.key}`}
                                                        control={
                                                            <Checkbox
                                                                size="small"
                                                                style={{padding: 2, marginLeft: 10}}
                                                                disabled={action === 'edit' ? this.isOptionChecked(_option) : false}
                                                                checked={
                                                                    this.state.selected_attributes[index]['options'][_index].hasOwnProperty('checked') 
                                                                        ?
                                                                            this.state.selected_attributes[index]['options'][_index]['checked'] === true
                                                                        :
                                                                            false
                                                                }
                                                                // checked={
                                                                //     action === 'edit'
                                                                //         ?
                                                                //             this.isOptionChecked(_option)
                                                                //         :
                                                                //             this.state.selected_attributes[index]['options'][_index].hasOwnProperty('checked') 
                                                                //                 ?
                                                                //                     this.state.selected_attributes[index]['options'][_index]['checked'] === true
                                                                //                 :
                                                                //                     false
                                                                // }
                                                                onChange={(checked) => {

                                                                    let selected_attributes = this.state.selected_attributes

                                                                    if(checked.target.checked === true){
                                                                    
                                                                        selected_attributes[index]['options'][_index]['checked'] = true;
                                                                    }else{

                                                                        delete selected_attributes[index]['options'][_index]['checked'];
                                                                    }

                                                                    this.setState({selected_attributes: selected_attributes})
                                                                }}
                                                            />
                                                        }
                                                        label={_option.value}
                                                    />
                                                )
                                            })}
                                        </FormGroup>
                                    </div>
                                )
                            })}

                            <div className='vertical'>
                                <span className='fs-12'>Please choose options to create the variable products.</span>

                                {this.state.options_error &&
                                
                                    <span className='fs-12 c-red'>Please choose multiple options from the attributes.</span>
                                }
                            </div>
                        </>
                    }

                    {this.state.selected_attributes.length > 0 &&

                        <Btn color="primary" variant="outlined" size="small" className="mt-10" onClick={() => {

                            let options = [];

                            this.state.selected_attributes.forEach((selected_attribute) => {

                                let _options = [];

                                if(selected_attribute.hasOwnProperty('options') && selected_attribute.options.length > 0){

                                    selected_attribute.options.forEach((_option) => {

                                        if(_option.hasOwnProperty('checked') && _option.checked === true){

                                            _options.push({value: _option.value, id: _option.id})
                                        }
                                    })

                                    if(_options.length > 0){

                                        options.push(_options);
                                    }
                                }
                            })

                            if(options.length > 0){

                                let attribute_products = this.recurse(options, '');

                                let _attribute_products = [];

                                attribute_products.map((_attribute_product) => {

                                    let codes = _attribute_product.split('-');

                                    let ids = [];
                                    let code = [];

                                    codes.forEach((_code) => {

                                        ids.push({id: _code.split('_')[1], code: _code.split('_')[0]})

                                        code.push(_code.split('_')[0])
                                    })

                                    _attribute_products.push({code: code.join('-'), price: '', qty: '', stock_status: '', ids: ids, status: '1'})
                                })

                                this.props.initAttributes(_attribute_products, this.state.selected_attributes);

                                this.setState({attribute_products: _attribute_products, options_error: false, show_manage_attributes: false})
                            }else{

                                this.setState({attribute_products: [], options_error: true, show_manage_attributes: true})
                            }
                        }}>
                            {action === 'add' ? 'Create' : 'Update'} Variable Products
                        </Btn>
                    }
                </Grid>
            </Grid>
        )
    }

    isOptionChecked = (option) => {

        let _return = false;

        let attribute_products = this.props.attribute_products;

        attribute_products.forEach((_attribute_product) => {

            const _id = _attribute_product.ids.findIndex(row => row.id === option.id)

            if(_id > -1){

                _return = true;
            }
        })

        return _return
    }

    optionStoreValue = (_store, _attribute_product, field) => {

        if(_attribute_product.hasOwnProperty('store')){

            let _stores = _attribute_product.store;

            const _store_row = _stores.find(row => row.store_id === _store.row_id);

            if(_store_row){

                let _value = _store_row[field]

                if(field == 'stock_status'){
                
                    if(_value == undefined){

                        _value = '';
                    }
                }

                if(field === 'status' && _value === ''){

                    return '1'
                }

                return _value
            }else{

                if(field === 'status'){

                    return '1'
                }
            }
        }else{

            if(field === 'status'){

                return '1'
            }
        }

        return '';
    }

    storeFieldError = (_store, _attribute_product, field) => {

        if(_attribute_product.hasOwnProperty('store')){
        
            let _stores = _attribute_product.store;

            const _store_row = _stores.find(row => row.store_id === _store.row_id);

            if(_store_row){

                if(_store_row.hasOwnProperty(field)){

                    return true;
                }
            }
        }

        return false;
    }

    uploadImage = (e, field, index) => {

        e.preventDefault();
    
        let reader = new FileReader();
        let file = e.target.files[0];

        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append("tmp_file_name", file);

        formData.append('allowed_types', 'jpg,jpeg,png,webp');

        formData.append('upload_dir', '/catalog/product/attribute_images');
		
		var self = this;
		
        this.setState({uploading_index: index})
		
        Api.post('imageuploader', formData, function (data) {

            self.setState({uploading_index: false});

            if(data.status === 'success'){

                let attribute_products = self.props.attribute_products

                attribute_products[index]['image'] = `${data.random_dir}${data.main_file_name}`
                attribute_products[index]['image_url'] = `${data.media_url}${data.upload_dir}/${data.random_dir}${data.main_file_name}`

                self.setState({attribute_products: attribute_products})
                self.props.updateAttributes(attribute_products)

            }else{

                self.props.updateErrorMessage(data.message)
            }
        });
	}
}

export default ProductVariables;